import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  Font
} from "@react-pdf/renderer";
import ATCOLogo from "./atco-logo.png";
import { BillOfLading } from "store/models/BillOfLading";
import { Asset } from "store/models/Asset";
import { Branch } from "store/models/Branch";
import { parseAddress } from "store/models/Address";
import {
  dateFromMMDDYYYY,
  feetToFeetInches,
  getTimezone,
  objectIdToDate,
  parsePhoneNumberOfDubiousLength,
  toDDMMMYYYY,
} from "utils/util";
import { isValid } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

function formatTime(date: Date) {
  return isValid(date)
    ? formatInTimeZone(new Date(date), getTimezone(), "hh:mm a z")
    : "";
}

function formatDate(date: Date) {
  return isValid(date)
    ? formatInTimeZone(new Date(date), getTimezone(), "MMMM dd, yyyy h:mm a zzz")
    : "";
}

const convertMinutesToHoursAndMinutes = (minutes: number) => {
  return { hours: Math.floor(minutes / 60), minutes: minutes % 60 };
};

const AddressInfo = ({ branch, bol }: { branch: Branch | undefined, bol: BillOfLading }) => (
  <View
    style={{
      width: "33%",
      fontSize: 6,
      display: "flex",
      rowGap: 2,
      flexDirection: "column",
      marginLeft: 8,
    }}
  >
    <Text style={{ textAlign: "right", fontSize: 12 }}>BOL # {bol.billOfLadingNumber}</Text>
    <Text style={{ textAlign: "right" }}>ATCO Structures & Logistics Ltd.</Text>
    <Text style={{ textAlign: "right" }}>{parseAddress(branch?.address)}</Text>
    <Text style={{ textAlign: "right" }}>T.: +1 (403) 292-7600 Fax: 403-292-7624</Text>
    <Text style={{ textAlign: "right" }}>Toll Free: 1-800-575-2826</Text>
    <Text style={{ textAlign: "right" }}>https://structures.atco.com/</Text>
  </View>
);

const Logo = () => (
  <View style={{ width: "33%" }}>
    <Image style={{ width: 100 }} src={ATCOLogo} />
  </View>
);

const FormField = ({
  height,
  width,
  name,
  value,
  suffix,
  style,
}: {
  height?: string | number;
  width: string | number;
  name: string;
  value: string | number;
  suffix?: string;
  style?: { [name: string]: string | number };
}) => (
  <View
    style={{
      width: width,
      height: height,
      backgroundColor: "#d7d7d7",
      borderRadius: 4,
      padding: 4,
      ...style,
    }}
  >
    <Text style={{ fontSize: 6 }}>{name.toLocaleUpperCase()}</Text>
    <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
      <Text style={{ fontSize: 8 }}>{value || " "}</Text>
      <Text style={{ fontSize: 6, paddingBottom: 4, paddingRight: 2 }}>
        {suffix}
      </Text>
    </View>
  </View>
);

const TopSection = (props: { bol: BillOfLading }) => (
  <View
    style={{
      width: "100%",
      alignItems: "center",
    }}
  >
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        marginTop: 6,
        alignItems: "center",
        columnGap: 6,
      }}
    >
      <FormField width="33%" name="AT" value={props.bol.pointOfOrigin} />
      <FormField width="33%" name="ORIGINATOR" value={props.bol.originator} />
      <FormField
        width="33%"
        name="DATE"
        value={
          props.bol.deliveryDate ? toDDMMMYYYY(dateFromMMDDYYYY(props.bol.deliveryDate))! : ""
        }
      />
    </View>
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        marginTop: 6,
        alignItems: "center",
        columnGap: 6,
      }}
    >
      <FormField width="33%" name="consignor" value={props.bol.consignor} />
      <FormField
        width="33%"
        name="phone"
        value={parsePhoneNumberOfDubiousLength(props.bol.consignorPhone)}
      />
      <FormField
        width="33%"
        name="project no."
        value={props.bol.projectNumber}
      />
    </View>
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        marginTop: 6,
        alignItems: "center",
        columnGap: 6,
      }}
    >
      <FormField
        width="67%"
        name="ADDRESS"
        value={props.bol.consignorAddress}
      />
      <FormField
        width="33%"
        name="PURCHASE ORDER NO."
        value={props.bol.purchaseOrderNumber}
      />
    </View>
  </View>
);

const Disclaimer = () => (
  <View style={{ fontSize: 6, marginTop: 6, padding: "0 4" }}>
    <Text>
      Received at the point of origin on the date specified, from the consignor
      mentioned herein, the property herein described, in apparent good order,
      except as noted (contents and conditions of contents of package unknown)
      marked, consigned and destined as indicated below, which the carrier
      agrees to carry and to deliver to the consignee at the said destination,
      if on its own authorized route or otherwise to cause to be carried by
      another carrier on the route to said destination, subject to the rates and
      classification in effect on the date of shipment.It is mutually agreed as
      to each carrier of all or any of the goods over all or any portion of the
      route to destination, and as to each party of any new time interested in
      all or any of the goods, that every service to be performed hereunder
      shall be subject to all the conditions not prohibited by law, whether
      permitted or written, which are hereby agreed by the consignor and
      accepted for its consignor and its assigns..
    </Text>
  </View>
);

const HazardousMaterialInstruction = () => (
  <View style={{ fontSize: 6, marginTop: 2, marginLeft: 2 }}>
    <Text>
      Hazardous Materials marked an &quot;X&quot; above require Carrier to
      comply with all applicable TDG regulations.
    </Text>
  </View>
);

const SpecialAgreementClause = ({ bol }: { bol: BillOfLading }) => (
  <View style={{ fontSize: 6, marginTop: 6, width: "50%" }}>
    <FormField
      width="100%"
      name="Special agreement between consignor and carrier"
      value={bol.specialAgreementBetweenConsignorAndCarrier}
    />
    <Text style={{ width: "100%", marginLeft: 2, marginTop: 1 }}>
      {" "}
    </Text>
  </View>
);

const DeclaredValuationClause = ({ bol }: { bol: BillOfLading }) => (
  <View style={{ fontSize: 6, marginTop: 6, width: "50%" }}>
    <FormField
      width="100%"
      name="DECLARED VALUATION"
      value={bol.declaredValuation || ""}
    />
    <Text style={{ width: "100%", marginLeft: 2, marginTop: 1 }}>
      Maximum liability of $2.00 per pound or $4.41 per kilogram unless declared
      valuation states otherwise.
    </Text>
  </View>
);

const CarrierInfo = ({ bol }: { bol: BillOfLading }) => (
  <View
    style={{ fontSize: 6, marginTop: 6, flexDirection: "row", columnGap: 4 }}
  >
    <FormField
      width="50%"
      name="CARRIER COMPANY NAME"
      value={bol.carrierCompanyName}
    />
    <FormField
      width="50%"
      name="PHONE"
      value={parsePhoneNumberOfDubiousLength(bol.carrierPhone)}
    />
  </View>
);

const LoadingTimeAtOrigin = ({ bol }: { bol: BillOfLading }) => {
  const { hours, minutes } = convertMinutesToHoursAndMinutes(
    bol.consignorLoadingTimeTotalMinutes || 0
  );

  return (
    <View
      style={{
        fontSize: 6,
        marginTop: 6,
        flexDirection: "row",
        columnGap: 4,
        alignItems: "center",
      }}
    >
      <Text style={{ width: "20%", marginTop: 1, marginRight: -8 }}>
        LOADING TIME AT ORIGIN
      </Text>
      <FormField width="15%" name=" " value={hours} suffix="HRS" />
      <FormField width="15%" name=" " value={minutes} suffix="MINS" />
      <FormField
        width="50%"
        name="DEPARTURE"
        value={bol.consignorDepartureTime ? formatTime(new Date(bol.consignorDepartureTime)) : ""}
        suffix="AM / PM"
      />
    </View>
  );
};

const Comments = ({ bol }: { bol: BillOfLading }) => (
  <FormField
    style={{ marginTop: 4 }}
    width="100%"
    height={50}
    name="COMMENTS"
    value={bol.comments}
  />
);

const Acknowledgements = ({ bol }: { bol: BillOfLading }) => (
  <>
    <View
      style={{
        width: "100%",
        fontSize: 8,
        flexDirection: "row",
        columnGap: 4,
        marginTop: 4,
      }}
    >
      <Text style={{ width: "50%" }}>ACKNOWLEDGEMENT AT ORIGIN</Text>
      <Text style={{ width: "50%" }}>ACKNOWLEDGEMENT AT DESTINATION</Text>
    </View>
    <View
      style={{
        width: "100%",
        fontSize: 8,
        flexDirection: "row",
        columnGap: 4,
        marginTop: 4,
      }}
    >
      <FormField
        width="30%"
        name="NAME / SIGNATURE OF CONSIGNOR"
        value={bol.acknowledgementAtOriginConsignorName}
      />
      <FormField
        width="20%"
        name="DATE"
        value={bol.acknowledgementAtOriginConsignorDate || ""}
      />
      <FormField
        width="30%"
        name="NAME / SIGNATURE OF CONSIGNEE"
        value={bol.acknowledgementAtDestinationConsigneeName}
      />
      <FormField
        width="20%"
        name="DATE"
        value={bol.acknowledgementAtDestinationConsigneeDate || ""}
      />
    </View>
    <View
      style={{
        width: "100%",
        fontSize: 8,
        flexDirection: "row",
        columnGap: 4,
        marginTop: 4,
      }}
    >
      <FormField
        width="30%"
        name="ACKNOWLEDGEMENT OF CARRIER"
        value={bol.acknowledgementAtOriginCarrierName}
      />
      <FormField
        width="20%"
        name="DATE"
        value={bol.acknowledgementAtOriginCarrierDate || ""}
      />
      <FormField
        width="30%"
        name="ACKKNOWLEDGEMENT OF CARRIER"
        value={bol.acknowledgementAtDestinationCarrierName}
      />
      <FormField
        width="20%"
        name="DATE"
        value={bol.acknowledgementAtDestinationCarrierDate || ""}
      />
    </View>
  </>
);

const AtDestinationFields = ({ bol }: { bol: BillOfLading }) => {
  const {
    hours: consignorLoadingTimeHours,
    minutes: consignorLoadingTimeMinutes,
  } = convertMinutesToHoursAndMinutes(
    bol.consignorLoadingTimeTotalMinutes || 0
  );

  const {
    hours: consigneeOtherTimeAtOriginOrDestinationHours1,
    minutes: consigneeOtherTimeAtOriginOrDestinationMinutes1,
  } = convertMinutesToHoursAndMinutes(
    bol.consigneeOtherTimeAtOriginOrDestinationTotalMinutes1 || 0
  );

  const {
    hours: consigneeOtherTimeAtOriginOrDestinationHours2,
    minutes: consigneeOtherTimeAtOriginOrDestinationMinutes2,
  } = convertMinutesToHoursAndMinutes(
    bol.consigneeOtherTimeAtOriginOrDestinationTotalMinutes2 || 0
  );

  const {
    hours: consigneeOtherTimeAtOriginOrDestinationHours3,
    minutes: consigneeOtherTimeAtOriginOrDestinationMinutes3,
  } = convertMinutesToHoursAndMinutes(
    bol.consigneeOtherTimeAtOriginOrDestinationTotalMinutes3 || 0
  );

  return (
    <>
      <Text
        style={{
          width: "100%",
          marginTop: 8,
          marginRight: -8,
          fontSize: 6,
          borderBottom: "1",
          borderColor: "#d7d7d7",
          borderStyle: "solid",
        }}
      >
        TO BE COMPLETED BY CONSIGNEE/CARRIER TIME AT DESTINATION
      </Text>

      <View
        style={{
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          columnGap: 4,
          marginTop: 4,
        }}
      >
        <FormField
          width="25%"
          name="SITE ARRIVAL"
          value={bol.consigneeSiteArrivalTime ? formatTime(new Date(bol.consigneeSiteArrivalTime)) : ""}
          suffix="AM / PM"
        />
        <FormField
          width="25%"
          name="TOTAL LOADING TIME"
          value={consignorLoadingTimeHours}
          suffix="HRS"
        />
        <FormField
          width="25%"
          name="TOTAL LOADING TIME"
          value={consignorLoadingTimeMinutes}
          suffix="MINS"
        />
        <FormField
          width="25%"
          name="SITE DEPARTURE"
          value={bol.consigneeSiteDepartureTime ? formatTime(new Date(bol.consigneeSiteDepartureTime)) : ""}
          suffix="AM / PM"
        />
      </View>

      <View
        style={{
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          columnGap: 4,
          marginTop: 4,
        }}
      >
        <Text style={{ width: "25%", fontSize: 5 }}>
          OTHER TIME AT ORIGIN OR DESTINATION (EXPLAIN)
        </Text>
        <FormField height={12} width="25%" name=" " value={consigneeOtherTimeAtOriginOrDestinationHours1} suffix="HRS" />
        <FormField height={12} width="25%" name=" " value={consigneeOtherTimeAtOriginOrDestinationMinutes1} suffix="MINS" />
        <FormField height={12} width="25%" name="REASON" value={bol.consigneeOtherTimeAtOriginOrDestinationExplanation1} />
      </View>

      <View
        style={{
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          columnGap: 4,
          marginTop: 4,
        }}
      >
        <View style={{ width: "25%" }} />
        <FormField height={12} width="25%" name=" " value={consigneeOtherTimeAtOriginOrDestinationHours2} suffix="HRS" />
        <FormField height={12} width="25%" name=" " value={consigneeOtherTimeAtOriginOrDestinationMinutes2} suffix="MINS" />
        <FormField height={12} width="25%" name="REASON" value={bol.consigneeOtherTimeAtOriginOrDestinationExplanation2} />
      </View>

      <View
        style={{
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          columnGap: 4,
          marginTop: 4,
        }}
      >
        <View style={{ width: "25%" }} />
        <FormField height={12} width="25%" name=" " value={consigneeOtherTimeAtOriginOrDestinationHours3} suffix="HRS" />
        <FormField height={12} width="25%" name=" " value={consigneeOtherTimeAtOriginOrDestinationMinutes3} suffix="MINS" />
        <FormField height={12} width="25%" name="REASON" value={bol.consigneeOtherTimeAtOriginOrDestinationExplanation3} />
      </View>
    </>
  );
};

const MiddleSection = (props: { bol: BillOfLading }) => (
  <View
    style={{
      width: "100%",
      marginTop: 6,
      alignItems: "center",
    }}
  >
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        columnGap: 6,
      }}
    >
      {objectIdToDate(props.bol._id) < new Date("2024-07-03") ? (
        <>
          <FormField
            width="50%"
            name="SCHEDULED PICKUP TIME AT ORIGIN"
            value={
              props.bol.scheduledPickupTimeAtOrigin
                ? formatTime(new Date(props.bol.scheduledPickupTimeAtOrigin))
                : ""
            }
            suffix="AM / PM"
          />
          <FormField
            width="50%"
            name="SCHEDULED DELIVERY TIME AT DESTINATION"
            value={
              props.bol.scheduledDeliveryTimeAtDestination
                ? formatTime(
                    new Date(props.bol.scheduledDeliveryTimeAtDestination)
                  )
                : ""
            }
            suffix="AM / PM"
          />
        </>
      ) : (
        <>
          <FormField
            width="50%"
            name="SCHEDULED PICKUP AT ORIGIN"
            value={
              props.bol.scheduledPickupTimeAtOrigin
                ? formatDate(new Date(props.bol.scheduledPickupTimeAtOrigin))
                : ""
            }
          />
          <FormField
            width="50%"
            name="SCHEDULED DELIVERY AT DESTINATION"
            value={
              props.bol.scheduledDeliveryTimeAtDestination
                ? formatDate(
                    new Date(props.bol.scheduledDeliveryTimeAtDestination)
                  )
                : ""
            }
          />
        </>
      )}
    </View>
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        marginTop: 6,
        alignItems: "center",
        columnGap: 6,
      }}
    >
      <FormField
        width="33%"
        name="CONSIGNEE NAME"
        value={props.bol.consigneeName}
      />
      <FormField
        width="33%"
        name="CONSIGNEE ADDRESS"
        value={props.bol.consigneeAddress}
      />
      <FormField
        width="33%"
        name="CONTRACT BILL TO ADDRESS"
        value={props.bol.billTo ? parseAddress(props.bol.billTo) : ""}
      />
    </View>
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        marginTop: 6,
        alignItems: "center",
        columnGap: 6,
      }}
    >
      <FormField
        width="100%"
        name="DESTINATION"
        value={props.bol.consigneeDestination}
      />
    </View>
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        marginTop: 6,
        alignItems: "center",
        columnGap: 6,
      }}
    >
      <FormField
        width="50%"
        name="CONTACT NAME"
        value={props.bol.consigneeContactName}
      />
      <FormField
        width="50%"
        name="CONTACT PHONE"
        value={parsePhoneNumberOfDubiousLength(props.bol.consigneeContactPhone)}
      />
    </View>
  </View>
);

const tableColumnWidths = {
  "# of Packages": "10%",
  Name: "40%",
  HM: "5%",
  Width: "10%",
  Length: "10%",
  Height: "10%",
  Weight: "10%",
  $: "10%",
};

const AssetTable = ({
  assets = [],
  bol,
}: {
  bol?: BillOfLading;
  assets?: Asset[];
}) => (
  <>
    {/* TABLE HEADERS */}
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        fontSize: 6,
        marginTop: 6,
        // borderBottom: "1 #d7d7d7 solid",
        // borderColor: "#d7d7d7",
        marginBottom: 2,
      }}
    >
      <Text
        style={{
          width: tableColumnWidths["# of Packages"],
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Number of packages
      </Text>
      <Text
        style={{
          width: tableColumnWidths.Name,
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Particulars of goods, marks and exceptions
      </Text>
      <Text
        style={{
          width: tableColumnWidths.HM,
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        HM &quot;R&quot;
      </Text>
      <Text
        style={{
          width: tableColumnWidths.Width,
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Approx. Width
      </Text>
      <Text
        style={{
          width: tableColumnWidths.Length,
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Approx. Length
      </Text>
      <Text
        style={{
          width: tableColumnWidths.Height,
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Approx. Height
      </Text>
      <Text
        style={{
          width: tableColumnWidths.Weight,
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Approx. Weight
      </Text>
      <Text
        style={{
          width: tableColumnWidths.$,
          textAlign: "center",
          fontWeight: 600,
        }}
      >
        Approx. Amount ($)
      </Text>
    </View>

    {/* ASSETS */}
    {assets.map(({ _id, serialNumber, category, subCategory, floorStyle }) => (
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          fontSize: 6,
          textAlign: "center",
          //   marginBottom: 1,
          borderBottom: "1",
          paddingTop: 4,
          paddingBottom: 4,
          borderColor: "#cccccc",
          borderStyle: "solid",
          backgroundColor: "#d7d7d7",
          //   borderRadius: 8,
        }}
      >
        <Text key={_id} style={{ width: tableColumnWidths["# of Packages"] }}>
          1
        </Text>
        <View key={_id} style={{ width: tableColumnWidths.Name }}>
          <Text>
            {serialNumber || "No Serial"} - {category || "No category"} -{" "}
            {subCategory || ""} - {floorStyle || "UNSPECIFIED FLOORTYPE"}
          </Text>
          <Text>{bol?.assetArticles[0].attributes.notes ? `Notes: ${bol?.assetArticles[0].attributes.notes}` : ""}</Text>
        </View>
        <Text key={_id} style={{ width: tableColumnWidths.HM }}>
          {bol?.assetArticles[0].attributes.hazardous ? "Yes" : "No"}
        </Text>
        <Text key={_id} style={{ width: tableColumnWidths.Width }}>
          {bol?.assetArticles[0].attributes.width ? feetToFeetInches(bol?.assetArticles[0].attributes.width) : ""}
        </Text>
        <Text key={_id} style={{ width: tableColumnWidths.Length }}>
          {bol?.assetArticles[0].attributes.length ? feetToFeetInches(bol?.assetArticles[0].attributes.length) : ""}
        </Text>/
        <Text key={_id} style={{ width: tableColumnWidths.Height }}>
          {bol?.assetArticles[0].attributes.height ? feetToFeetInches(bol?.assetArticles[0].attributes.height) : ""}
        </Text>
        <Text key={_id} style={{ width: tableColumnWidths.Weight }}>
          {bol?.assetArticles[0].attributes.weight}
        </Text>
        <Text key={_id} style={{ width: tableColumnWidths.$ }}>
          {bol?.assetArticles[0].attributes.amount
            ? `${bol?.assetArticles[0].attributes.amount.amount} ${bol?.assetArticles[0].attributes.amount.currency}`
            : "-"}
        </Text>
      </View>
    ))}

    {/* ASSET ARTICLES */}
    {bol?.additionalItems.map(
      (
        {
          quantity,
          description,
          hazardous,
          width,
          length,
          height,
          weight,
          amount,
        },
        index
      ) => (
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            fontSize: 6,
            textAlign: "center",
            //   marginBottom: 1,
            borderBottom: "1",
            paddingTop: 4,
            paddingBottom: 4,
            borderColor: "#cccccc",
            borderStyle: "solid",
            backgroundColor: "#d7d7d7",
            //   borderRadius: 8,
          }}
        >
          <Text
            key={`assetArticle-${index}`}
            style={{ width: tableColumnWidths["# of Packages"] }}
          >
            {quantity}
          </Text>

          <View key={`assetArticle-${index}`} style={{ width: tableColumnWidths.Name }}>
            <Text>{description}</Text>
            <Text>{bol?.additionalItems[index].notes ? `Notes: ${bol?.additionalItems[index].notes}` : ""}</Text>
          </View>
          <Text
            key={`assetArticle-${index}`}
            style={{ width: tableColumnWidths.HM }}
          >
            {hazardous ? "Yes" : "No"}
          </Text>
          <Text
            key={`assetArticle-${index}`}
            style={{ width: tableColumnWidths.Width }}
          >
            {width ? feetToFeetInches(width) : ""}
          </Text>
          <Text
            key={`assetArticle-${index}`}
            style={{ width: tableColumnWidths.Length }}
          >
            {length ? feetToFeetInches(length) : ""}
          </Text>
          <Text
            key={`assetArticle-${index}`}
            style={{ width: tableColumnWidths.Height }}
          >
            {height ? feetToFeetInches(height) : ""}
          </Text>
          <Text
            key={`assetArticle-${index}`}
            style={{ width: tableColumnWidths.Weight }}
          >
            {weight}
          </Text>
          <Text
            key={`assetArticle-${index}`}
            style={{ width: tableColumnWidths.$ }}
          >
            {amount.amount ? `${amount.amount} ${amount.currency}` : ""}
          </Text>
        </View>
      )
    )}
  </>
);

const BillOfLadingPdf = ({
  billOfLading,
  assets,
  branch,
}: {
  billOfLading: BillOfLading;
  assets: Asset[];
  branch: Branch | undefined;
}) => {

  Font.register({
    family: 'Roboto',
    fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }],
  });

  return (
    // <PDFViewer style={{ width: "100%", height: "100vh" }}>
    <Document>
      <Page
        size="LETTER"
        style={{
          padding: 8,
          fontFamily: "Roboto"
        }}
      >
        <View style={{ flexDirection: "row", columnGap: 4 }}>
          <Logo />
          <View style={{ width: "33%" }}>
            <Text style={{ fontSize: 24 }}>BILL OF LADING</Text>
            <Text style={{ fontSize: 8, textAlign: "center", marginRight: 4 }}>
              NON NEGOTIABLE
            </Text>
          </View>
          <AddressInfo branch={branch} bol={billOfLading} />
        </View>

        <TopSection bol={billOfLading} />

        <Disclaimer />

        <MiddleSection bol={billOfLading} />

        <AssetTable assets={assets} bol={billOfLading} />

        <HazardousMaterialInstruction />

        <View style={{ flexDirection: "row", columnGap: 4 }}>
          <SpecialAgreementClause bol={billOfLading} />
          <DeclaredValuationClause bol={billOfLading} />
        </View>


        <CarrierInfo bol={billOfLading} />

        <LoadingTimeAtOrigin bol={billOfLading} />

        <AtDestinationFields bol={billOfLading} />

        <Comments bol={billOfLading} />

        <Acknowledgements bol={billOfLading} />
      </Page>
    </Document>
    // </PDFViewer>
  );
};

export default BillOfLadingPdf;
