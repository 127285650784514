import imageCompression from "browser-image-compression";
import React, { useEffect, useState } from "react";
import { Photo } from "store/models/Photo";
import { proxyUrl } from "utils/util";

interface Props {
  images: Array<Photo | string>;
  imagesLoading: boolean;
  dataId: string;
}

const useCompressImages = ({
  images,
  imagesLoading,
  dataId,
}: Props) => {
  
  const [compressedImages, setCompressedImages] = useState<Array<string>>([]);
  const [isCompressing, setIsCompressing] = useState(false);
  const [currentDataId, setCurrentDataId] = useState<string>("");
  const [imagesAlreadyCompressed, setImagesAlreadyCompressed] = useState<{img: string, compressedUrl: string}[]>([])

  useEffect(() => {
    // this will prevent this component from re-firing if the ID of the data is the same and the length of the image array hasn't changed

    currentDataId !== dataId && !imagesLoading && setCurrentDataId(dataId);

    if (
      (currentDataId === dataId && compressedImages.length === images.length) ||
      (!currentDataId && !dataId) ||
      imagesLoading || isCompressing
    ) return;

    if (!images.length) {
      setImagesAlreadyCompressed([])
      setCompressedImages([]);
      return;
    }



    const imagesToAdd = getImagesToAdd()
    const imagesToRemove = getImagesToRemove()

    async function compressImages() {
      let newImagesAlreadyCompressed = [...imagesAlreadyCompressed]

      const compressed: string[] = await Promise.all(
        imagesToAdd.map(async (img) => {
          if (typeof img === "string") {
            newImagesAlreadyCompressed.push({img, compressedUrl: img})
            return img;
          }

          const photo = img as Photo;
          const response = await fetch(proxyUrl + photo.web_url);
          const blob = await response.blob();

          const compressedBlob = await imageCompression(blob as File, {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
          });
          const compressedBlobUrl = URL.createObjectURL(compressedBlob);
          newImagesAlreadyCompressed.push({img: photo.web_url, compressedUrl: compressedBlobUrl})
          return compressedBlobUrl
        })
      );

      setImagesAlreadyCompressed(newImagesAlreadyCompressed) 
      setCompressedImages(prev => [...prev, ...compressed]);   
      return
    }
   
    if (imagesToAdd.length) {
      setIsCompressing(true);
      compressImages().then(() => setIsCompressing(false));
    }

    if (imagesToRemove.length) {
      setCompressedImages(prev => prev.filter(img => !imagesToRemove.some(image => image.compressedUrl === img)))
      setImagesAlreadyCompressed(prev => prev.filter(img => !imagesToRemove.some(image => image.img === img.img)))
    }


 

  }, [images, imagesLoading, dataId]);

  function getImagesToAdd() {
    return images.filter(
      (img) =>
        (typeof img === "string" &&
          !imagesAlreadyCompressed.some((image) => image.img === img)) ||
        (typeof img !== "string" &&
          !imagesAlreadyCompressed.some((image) => image.img === img.web_url))
    );
  }
  function getImagesToRemove() {
    return imagesAlreadyCompressed.filter((img) => {
      return !images.filter((image) => {
        if (typeof image === "string") {
          return img.img === image;
        } else {
          return img.img === image.web_url;
        }
      }).length;
    });
  }


  return { compressedImages, isCompressing };
};

export default useCompressImages;
