import Slider from 'react-slick';
import { useState, useRef, useEffect } from 'react';
import { alpha, styled, SxProps, Theme } from '@mui/material/styles';
import { Box, Button, CircularProgress, Grid, IconButton } from '@mui/material';
import Image from 'components/image/Image';
import { CarouselArrowIndex } from 'components/carousel';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Photo } from 'store/models/Photo';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Iconify from "components/iconify/iconify"
import { m } from 'framer-motion';
import AssetImage from 'components/AssetImage';
import { useAppDispatch } from 'store/store';
import { simpleGlobalMessage } from 'store/slices/systemSlice';
import Lightbox, { Slide } from 'yet-another-react-lightbox';
import Download from "yet-another-react-lightbox/plugins/download";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import DownloadIcon from '@mui/icons-material/Download';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import { proxyUrl } from 'utils/util';

// ----------------------------------------------------------------------

export const THUMB_SIZE = 64;

const RootStyle = styled('div')(({ theme }) => ({
  '& .slick-slide': {
    float: theme.direction === 'rtl' ? 'right' : 'left',
    '&:focus': { outline: 'none' },
  },
}));

// ----------------------------------------------------------------------

type Props = {
  images: Array<Photo | string>;
  handleImageUpload: (form: FormData) => Promise<any>;
  boxSx?: SxProps<Theme>;
  deleteImage: (image: Photo) => void; 
  permission: boolean;
  isLoading?: boolean;
  allowMultiple?: boolean;
};

export default function ProductDetailsCarousel({ 
  images,
  handleImageUpload,
  boxSx,
  deleteImage,
  permission,
  isLoading = false,
  allowMultiple = false,
}: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nav1, setNav1] = useState<Slider>();
  const [nav2, setNav2] = useState<Slider>();
  const slider1 = useRef<Slider | null>(null);
  const slider2 = useRef<Slider | null>(null);
  const fileInputControl = useRef<HTMLInputElement>(null);
  const [openLightbox, setOpenLightbox] = useState(false);

  const [enableImageDelete, setImageDelete] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const disableActions = isLoading || isSubmitting;
  const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/gif'];
  const dispatch = useAppDispatch();

  const formatImage = async(e: React.ChangeEvent<HTMLInputElement>) => {
    try { const file = e.target.files![0];
      setSubmitting(true);
      if(allowMultiple && e.target.files){
        await Promise.all(
          Array.from(e.target.files).map(
            (file: File) =>
              new Promise((resolve, reject) => {
                uploadFile(file).then(resolve).catch(reject)
              })
          )
        );
      } else {
        await uploadFile(file);
      }
      setSubmitting(false)} catch (error) {
        dispatch(simpleGlobalMessage(`An error occurred while uploading the image. ${error || ''}  Please try again.`));
        setSubmitting(false);
    }
   

    async function uploadFile (file: File){
      if (!file) return;
    
      const maxFileSize = 10 * 1024 * 1024; // 10 MB
      if (file.size > maxFileSize) {
        dispatch(
          simpleGlobalMessage(
            `The file was larger than ${maxFileSize / (1024 * 1024)} MB maximum!`
          )
        );
        return;
      }
        
      const form = new FormData();
      form.append("file", file);
      await handleImageUpload(form);
    }
  }

  function noImages (){
    return !Boolean(images.length);
  }

  function imagesProvided (){
    return Boolean(images.length);
  }

  const handleOpenLightbox = (index: number) => {
    setOpenLightbox(true);

  };

  const settings1 = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: false,
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
  };

  const settings2 = {
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    variableWidth: true,
    centerPadding: '0px',
    slidesToShow: images.length > 3 ? 3 : images.length,
  };

  useEffect(() => {
    if (slider1.current) {
      setNav1(slider1.current);
    }
    if (slider2.current) {
      setNav2(slider2.current);
    }
  }, []);

  const handlePrevious = () => {
    slider2.current?.slickPrev();
  };

  const handleNext = () => {
    slider2.current?.slickNext();
  };

  const slides: Slide[] = images.map((img: any) => ({src: img.web_url  }));

  function isPhoto(image: string | Photo): image is Photo {
    return (image as Photo).web_url !== undefined;
  }

 async function handleDownload(image: typeof images[0]) {
    if (isPhoto(image)) {
      const res = await fetch(proxyUrl + image.web_url);
      const blob = await res.blob();
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.setAttribute("download", image.fileName);
      a.click();
    }

  }


  return (
    <RootStyle>
      <Box sx={{ p: 1 }}>
        <Box
          sx={{
            zIndex: 0,
            borderRadius: 2,
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Slider {...settings1} asNavFor={nav2} ref={slider1}>
            {isLoading || isSubmitting ? (
              <Box
                component="span"
                sx={{
                  width: 1,
                  lineHeight: 0,
                  display: "block",
                  overflow: "hidden",
                  position: "relative",
                  py: "50%",
                  "& .wrapper": {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    lineHeight: 0,
                    position: "absolute",
                    backgroundSize: "cover !important",
                  },
                }}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              </Box>
            ) : noImages() ? (
              <AssetImage />
            ) : (
              images.map((img, index) => {
                const image = img as Photo;
                const imageUrl = String(img);
                const isPhoto = Boolean(image.web_url);
                return isPhoto ? (
                  <Image
                    key={image.web_url}
                    alt="large image"
                    src={image.web_url}
                    ratio="1/1"
                    onClick={() => handleOpenLightbox(index)}
                    sx={{ cursor: "zoom-in" }}
                  />
                ) : (
                  <Image
                    key={imageUrl}
                    alt="large image"
                    src={imageUrl}
                    ratio="1/1"
                    onClick={() => handleOpenLightbox(index)}
                    sx={{ cursor: "zoom-in" }}
                  />
                );
              })
            )}
          </Slider>
          {imagesProvided() && (
            <CarouselArrowIndex
              index={currentIndex}
              total={images.length}
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          )}
        </Box>
      </Box>
      <input
        id="image-upload-input"
        type="file"
        name="photoUpload"
        accept={allowedMimeTypes.join(",")}
        multiple={allowMultiple}
        ref={fileInputControl}
        onChange={formatImage}
        style={{ display: "none" }}
      />
      <Grid container spacing={1} alignContent="center">
        {(noImages() && permission && (
          <Grid item xs sx={{ m: 2, mt: 0 }}>
            <Button
              onClick={() => fileInputControl.current?.click()}
              startIcon={<AddPhotoAlternateIcon />}
              variant="outlined"
              fullWidth
              disabled={disableActions}
            >
              Add Image
            </Button>
          </Grid>
        )) ||
          (permission ? (
            <Grid item xs={1} display="flex">
              <IconButton
                onClick={() => fileInputControl.current?.click()}
                sx={{ margin: "auto" }}
                disabled={disableActions}
              >
                <AddPhotoAlternateIcon />
              </IconButton>
            </Grid>
          ) : (
            <Grid item xs={1} />
          ))}
        {imagesProvided() && (
          <>
            <Grid item xs={10} justifyContent="center">
              <Box
                sx={{
                  my: 3,
                  mx: "auto",
                  "& .slick-current .isActive": { opacity: 1 },
                  ...(images.length === 1 && { maxWidth: THUMB_SIZE * 1 + 16 }),
                  ...(images.length === 2 && { maxWidth: THUMB_SIZE * 2 + 32 }),
                  ...(images.length === 3 && { maxWidth: THUMB_SIZE * 3 + 48 }),
                  ...(images.length === 4 && { maxWidth: THUMB_SIZE * 3 + 48 }),
                  ...(images.length >= 5 && { maxWidth: THUMB_SIZE * 6 }),
                  ...(images.length > 2 && {
                    position: "relative",
                    "&:before, &:after": {
                      top: 0,
                      zIndex: 9,
                      content: "''",
                      height: "100%",
                      position: "absolute",
                      width: (THUMB_SIZE * 2) / 3,
                      backgroundImage: (theme) =>
                        `linear-gradient(to left, ${alpha(
                          theme.palette.background.paper,
                          0
                        )} 0%, ${theme.palette.background.paper} 100%)`,
                    },
                    "&:after": { right: 0, transform: "scaleX(-1)" },
                  }),
                  overflow: "hidden",
                  ...boxSx,
                }}
              >
                <Slider {...settings2} asNavFor={nav1} ref={slider2}>
                  {images.map((img, index) => {
                    const image = img as Photo;
                    const imageUrl = String(img);
                    const isPhoto = Boolean(image.web_url);
                    return isPhoto ? (
                      <Box
                        key={image.web_url}
                        sx={{ px: 0.75, position: "relative" }}
                        component={m.div}
                      >
                        <Image
                          disabledEffect
                          alt="thumb image"
                          src={image.web_url}
                          sx={{
                            width: THUMB_SIZE,
                            height: THUMB_SIZE,
                            borderRadius: 1.5,
                            cursor: "pointer",
                            ...(currentIndex === index && {
                              border: (theme) =>
                                `solid 3px ${theme.palette.primary.main}`,
                            }),
                          }}
                        />
                        {enableImageDelete && (
                          <Box sx={{ top: 4, right: 8, position: "absolute" }}>
                            <IconButton
                              size="small"
                              onClick={() => deleteImage(image)}
                              disabled={disableActions}
                              sx={{
                                p: "2px",
                                color: "common.white",
                                bgcolor: (theme) =>
                                  alpha(theme.palette.grey[900], 0.72),
                                "&:hover": {
                                  bgcolor: (theme) =>
                                    alpha(theme.palette.grey[900], 0.48),
                                },
                              }}
                            >
                              <Iconify icon="eva:close-fill" />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box
                        key={imageUrl}
                        sx={{ px: 0.75, position: "relative" }}
                        component={m.div}
                      >
                        <Image
                          disabledEffect
                          alt="thumb image"
                          src={imageUrl}
                          sx={{
                            width: THUMB_SIZE,
                            height: THUMB_SIZE,
                            borderRadius: 1.5,
                            cursor: "pointer",
                            ...(currentIndex === index && {
                              border: (theme) =>
                                `solid 3px ${theme.palette.primary.main}`,
                            }),
                          }}
                        />
                      </Box>
                    );
                  })}
                </Slider>
              </Box>
            </Grid>
            <Grid item xs={1} display="flex">
              {permission && imagesProvided() && (
                <IconButton
                  sx={{ margin: "auto" }}
                  color="error"
                  onClick={() => setImageDelete(!enableImageDelete)}
                  disabled={disableActions}
                >
                  <DeleteForeverIcon />
                </IconButton>
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Lightbox
        open={openLightbox}
        close={() => setOpenLightbox(false)}
        slides={slides}
        plugins={[Zoom, Download, Counter, Thumbnails]}
        render={{
          buttonDownload: () => (
            <IconButton onClick={() => handleDownload(images[currentIndex])}>
            <DownloadIcon  />
            </IconButton>
              
          ),
        }}
        index={currentIndex}
        on={{
          view: ({index}) => setCurrentIndex(index)
        }}
        thumbnails={{border: 1, borderColor: 'none', height: 120}}
        
      />
    </RootStyle>
  );
}
